import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/laminat',
    component: () => import(/* webpackChunkName: "group-shop" */ '../views/Shop.vue'),
    children: [
      { path: '', name: 'ItemsList', component: () => import(/* webpackChunkName: "group-shop" */ '../components/ItemsList.vue') },
      { path: 'quick-step/:id', name: 'Item', component: () => import(/* webpackChunkName: "group-shop" */ '../views/Item.vue') },
      { path: 'quick-step/accessories/:id', name: 'Accessory', component: () => import(/* webpackChunkName: "group-shop" */ '../views/Item.vue') },
      { path: 'cart', name: 'Cart', component: () => import(/* webpackChunkName: "group-shop" */ '../views/Cart.vue') }
    ]
  },
  {
    path: '/laminat/manage',
    name: 'Manage',
    component: () => import(/* webpackChunkName: "group-manage" */ '../views/Manage.vue'),
    beforeEnter: async (to, from, next) => {
      let resp = await axios.post('/api/login')
      if (resp.data == true) next()
      else next('/')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template lang="pug">
v-content.grey.darken-4
  section#hero
    v-img.jumbotron(dark, :src="hero_image", gradient="to top, rgba(40,40,60, .0), rgba(25,32,62, .8)")
      v-container.fill-height.text-center
        v-row.fill-height.flex-column.flex-nowrap(align="center" justify="center").text-center
          v-spacer
          v-col.pa-3
            h1(data-aos="fade", data-aos-delay="300").mb-2.display-3 Ламинат От и До
            .mb-5.headline(data-aos="fade", data-aos-delay="900") Абсолютная свобода выбора!
            v-btn.mt-5(color="primary" exact :to="{name: 'ItemsList'}" data-aos="fade", data-aos-delay="1300")
              v-icon(left) mdi-cart-outline
              | Купить
          v-spacer
          v-btn(large, icon, @click="$vuetify.goTo('#why')", data-aos="fade", data-aos-anchor="hero", data-aos-delay="1400")
            v-icon(x-large) mdi-chevron-down

  section#why
    v-container
      v-row(align="center").my-5.text-center
        v-col(cols=12)
          h3.display-1 Найдите свой собственный стиль
        v-col(cols=12)
          v-row
            v-col(cols=12 sm=6 md=3)
              v-card(data-aos="fade-right").elevation-0.transparent
                v-card-text
                  v-icon(x-large).primary--text mdi-account-multiple
                v-card-title.justify-center.headline Расскажем
                v-card-text.subheading чем отличается хорошее напольное покрытие от остальных
            v-col(cols=12 sm=6 md=3)
              v-card(data-aos="fade-up-right").elevation-0.transparent
                v-card-text
                  v-icon(x-large).primary--text mdi-help-circle-outline
                v-card-title.layout.justify-center.headline Поможем
                v-card-text.subheading из всего многообразия ламината, паркета и пробки посоветуем именно ваш вариант пола
            v-col(cols=12 sm=6 md=3)
              v-card(data-aos="fade-up-left").elevation-0.transparent
                v-card-text
                  v-icon(x-large).primary--text mdi-cart
                v-card-title.layout.justify-center.headline Покупайте
                v-card-text.subheading выгодно - по ценам производителя, по акциям и со скидками
            v-col(cols=12 sm=6 md=3)
              v-card(data-aos="fade-left").elevation-0.transparent
                v-card-text
                  v-icon(x-large).primary--text mdi-share
                v-card-title.layout.justify-center.headline Делитесь
                v-card-text.subheading рекомендуйте нас своим друзьям и они смогут воспользоваться вашей скидкой     

  section#sales
    v-container(fluid)
      v-row(align="center").my-5.text-center
        v-col(cols=12)
          h3.display-1 Акции
      v-row(align="center" justify="center")
        v-col(cols=12 lg=10)
          v-row(align="center" justify="space-around")
            v-col(cols=12 md=6 lg=4 v-for="s in sales" :key="s.i")
              v-card.text-center(exact :to="{name: 'ItemsList'}" hover)
                v-img(:src="require(`../assets/sales/${s.i}.jpg`)" contain max-height=600)
                v-card-subtitle.subheading {{ s.desc }}
  
  section#carousel
    v-container(fluid)
      v-row(align="center").my-5.text-center
        v-col(cols=12)
          h3.display-1 Вдохновляющие интерьеры
      v-row
        carousel-3d(autoplay, :autoplay-timeout="4000", :space="400", :width="600", :height="450")
          slide(v-for="n, i) in 8" :index="i" :key="i")
            v-img.carousel-item(:src="require(`../assets/carousel/${i}.jpg`)" alt="interior example")
      v-col(cols=12).mb-5

  section#top-seller
    v-parallax(:src="parallax_image", alt="quick-step interior", height="430").text-center.my-5
      v-container(fluid).pa-0
        v-row(align="center" justify="center")
          v-col(cols=12 align="center" justify="center")
            .display-1.white--text.mb-4 Лидер продаж
            v-img(src="../assets/qs100.jpg", alt="Quick-Step" width=218 contain)
          v-col(cols=12 sm=10 md=8 lg=5 xl=4).title.hidden-sm-and-down
            p.text-left Откройте мир своей мечты с напольными покрытиями
            p.text-right QUICK-STEP бельгийской компании UNILIN
          v-col(cols=12)
            h3.subheading Только у нас полный ассортимент и цены от производителя!
          v-col(cols=12 xl=8)
            v-row
              v-col(cols=12 md=4)
                h2.headline ЛАМИНАТ
              v-col(cols=12 md=4)
                h2.headline ПАРКЕТНАЯ ДОСКА
              v-col(cols=12 md=4)
                h2.headline ВИНИЛОВАЯ ПЛИТКА
  
  section#new_products
    v-container(fluid)
      v-row(align="center").my-5.text-center
        v-col(cols=12)
          h3.display-1 Новинки
      v-row(align="center" justify="center")
        v-col(cols=12 lg=10).overlay-text
          v-row(align="center" justify="center")
            v-col(cols=12 sm=6 md=4 v-for="item in Object.values(new_products)" :key="item.i")
              v-card(:img="require(`../assets/new/${item.i}.jpg`)" exact :to="{name: 'ItemsList'}" hover)
                v-responsive(height="320px")
                v-card-title.py-1.justify-center.headline.text-center {{ item.title }}
                v-card-text.caption.text-center {{ item.desc }}

  section#contacts
    div(itemscope, itemtype="http://schema.org/LocalBusiness")
      v-container(fluid)
        v-row(justify="center").my-5          
          v-col(xs=12 sm=6 md=4)
            v-card.elevation-0.transparent
              v-card-title(primary-title).layout.justify-center
                .headline О нас
              v-card-text
                h3(itemprop="name") Ламинат От и До
                v-btn(icon, color="light-blue darken-3", href="https://vk.com/laminat_syzran", target="_blank", rel="noopener")
                  v-icon.mdi-light mdi-vk
                v-btn(icon, color="orange darken-3", href="https://ok.ru/group/52856712265917", target="_blank", rel="noopener")
                  v-icon.mdi-light mdi-odnoklassniki
                //- v-btn(icon, color="grey", href="https://www.instagram.com/laminat_syzran/", target="_blank", rel="noopener")
                  v-icon.mdi-light mdi-instagram
          v-col(xs=12 sm=6 md=4)
            v-card.elevation-0.transparent
              v-card-title(primary-title).layout.justify-center
                .headline Контакты
              v-card-text
                v-list.transparent
                  v-list-item(href="tel:+79179750548")
                    v-list-item-action
                      v-icon.primary--text mdi-phone
                    v-list-item-content
                      v-list-item-title(itemprop="telephone") +7 (917) 975-05-48
                  v-list-item(@click="map = true")
                    v-list-item-action
                      v-icon.primary--text mdi-map-marker
                    v-list-item-content
                      v-list-item-title(itemprop="address", itemscope, itemtype="http://schema.org/PostalAddress")
                        span(itemprop="postalCode").hidden-sm-and-down 446026
                        span.hidden-sm-and-down , 
                        span(itemprop="addressLocality") г. Сызрань
                        span , 
                        span(itemprop="streetAddress") ул. Рабочая, д. 129                        
                  v-list-item(href="mailto:laminat.syzran@gmail.com")
                    v-list-item-action
                      v-icon.primary--text mdi-email
                    v-list-item-content
                      v-list-item-title laminat.syzran@gmail.com

  v-footer.grey.darken-4

  v-dialog(v-model="map" fullscreen transition="dialog-bottom-transition" scrollable)
    v-card(tile)
      v-toolbar(flat color="primary")
        v-btn(icon, @click.native="map = false")
          v-icon mdi-close
        v-toolbar-title Карта
      v-card-text.fill-height.google-maps
        v-container(fill-height fluid)
          v-layout(row)
            v-flex
              iframe(frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?hl=ru&q=г. Сызрань, ул. Рабочая 129&ie=UTF8&t=roadmap&z=16&iwloc=B&output=embed")
</template>

<script>
import hero_image from '../assets/hero.jpg'
import parallax_image from '../assets/parallax.jpg'

export default {
  name: 'Home',
  data() {
    return {
      map: false,
      hero_image: hero_image,
      parallax_image: parallax_image,
      sales: [
        {
          i: 1,
          desc: 'Распродажа коллекции QUICK-STEP RUSTIC - 25%'
        },
        // {
        //   i: 2,
        //   desc: 'До 31 мая 2020 г. скидка 15% на ламинат PERGO'
        // },
        // {
        //   i: 3,
        //   desc:
        //     '-30% на выборочные декоры из коллекции Quick-Step Classic c 18.05.2020 г. по 28.06.2020 г.'
        // },
        // {
        //   i: 4,
        //   desc:
        //     '-25% на выборочные декоры из коллекции Quick-Step Impressive с 22.05.2020 г. по 02.08.2020 г.'
        // }
        {
          i: 5,
          desc: 'Акция с 19.03.2021г. до 30.05.2021г. на коллекцию ламината Quick-Step Classic - 20%'
        },
        {
          i: 6,
          desc: 'Акция с 19.03.2021г. до 30.05.2021г. на коллекцию ламината Quick-Step Eligna - 17%'
        }
      ],
      new_products: [
        {
          i: 8,
          title: 'QUICK-STEP CASTLE',
          desc: '2 способа укладки И НЕПРЕВЗОЙДЕННАЯ ВЛАГОСТОЙКОСТЬ'
        },
        {
          i: 6,
          title: 'QUICK-STEP MAJESTIC',
          desc: 'ПОТРЯСАЮЩИЙ ФОРМАТ ПЛАНОК И ИСКЛЮЧИТЕЛЬНАЯ ВОДОСТОЙКОСТЬ'
        },
        {
          i: 5,
          title: 'CORKSTYLE LOFT',
          desc: 'УНИКАЛЬНЫЕ ПРОБКОВЫЕ ПОЛЫ ИЗ ШВЕЙЦАРИИ'
        },
        {
          i: 4,
          title: 'PERGO',
          desc: 'ЕСТЬ ПРОСТО ЛАМИНАТ, А ЕСТЬ ЛАМИНАТ PERGO'
        },
        {
          i: 2,
          title: 'QUICK-STEP LIVYN',
          desc: 'РОСКОШНЫЕ ВИНИЛОВЫЕ ПОЛЫ'
        },
        {
          i: 1,
          title: 'QUICK-STEP IMPRESSIVE',
          desc: 'ПЕРВЫЙ НАСТОЛЬКО УСТОЙЧИВЫЙ К ВЛАГЕ ЛАМИНАТ'
        }
      ]
    }
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  methods: {},
  metaInfo: {
    title: 'Ламинат в Сызрани. Купить по отличной цене!',
    // titleTemplate: '%s ← laminat-syzran.ru',
    meta: [
      {
        name: 'description',
        content:
          'Купить ламинат в Сызрани. Лучшее качество, привлекательные цены. Магазин ЛАМИНАТ ОТ И ДО. Сызрань, ул. Рабочая, 129'
      },
      {
        name: 'keywords',
        content: 'ламинат в сызрани цены, купить ламинат, ламинат сызрань, напольные покрытия сызрань'
      }
    ],
    links: [{ rel: 'canonical', href: 'https://laminat-syzran.ru/' }]
  }
}
</script>

<style lang="stylus">
#hero
  h1
    text-transform uppercase
    font-weight 500

  .jumbotron
    height 100vh

.carousel-item
  cursor pointer

#new_products
  .overlay-text
    text-transform uppercase

    .v-card__title
      text-shadow -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
      letter-spacing 4px
      font-weight 800

    .v-card__text
      background-color rgba(0, 0, 0, 0.226)
      padding 0.5rem

#contacts
  .list__tile__content
    overflow visible

    @media (max-width 960px)
      font-size 14px

.google-maps iframe
  width 100%
  height 100%
</style>
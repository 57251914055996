import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items: [],
    accessories: [],
    page: 1,
    pageSize: 12,
    cart: [], // { article: '', count: 1, total: 2000 }
    favorites: [] // TODO favorites
  },
  getters: {
    totalSum: state => {
      return state.cart.map(x => x.total).reduce((a, b) => a + b, 0)
    },
    collections: state => {
      return state.items.map(x => x.collection).filter((v, i, a) => a.indexOf(v) === i)
    },
    grooves_types: state => {
      return state.items.map(x => x.grooves_type).filter((v, i, a) => a.indexOf(v) === i)
    },
    colors: state => {
      return state.items.map(x => x.color).filter((v, i, a) => a.indexOf(v) === i)
    },
    gloss_levels: state => {
      return state.items.map(x => x.gloss_level).filter((v, i, a) => a.indexOf(v) === i && v !== null)
    },
    water_reses: state => {
      return state.items.map(x => x.water_res).filter((v, i, a) => a.indexOf(v) === i)
    },
    classes: state => {
      return state.items.map(x => x.class).filter((v, i, a) => a.indexOf(v) === i)
    },
    pageItems: state => {
      return state.items.slice(
        (state.page - 1) * state.pageSize,
        state.page * state.pageSize
      )
    },
    pagesCount: state => {
      return Math.ceil(state.items.length / state.pageSize)
    },
    getCartItem: (state) => (article) => {
      return state.cart.find(x => x.article == article)
    },
    getItemById: (state) => (id) => {
      if (id) {
        return state.items.find(item => {
          return item.article === id
        })
      }
    },
    getAccById: (state) => (id) => {
      if (id) {
        return state.accessories.find(a => {
          return a.article === id
        })
      }
    }
  },
  mutations: {
    SET_DATA(state, data) {
      state.items = data.items
      state.accessories = data.accessories
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    ADD_TO_CART(state, payload) {
      const item = state.cart.find(x => x.article == payload.article)
      if (item)
        Object.assign(item, payload)
      else
        state.cart.push(payload)
      localStorage.cart = JSON.stringify(state.cart)
    },
    REMOVE_FROM_CART(state, payload) {
      state.cart.splice(state.cart.indexOf(payload), 1)
      localStorage.cart = JSON.stringify(state.cart)
    },
    LOAD_CART(state, payload) {
      state.cart = payload
    },
    EMPTY_CART(state) {
      state.cart = []
      localStorage.cart = JSON.stringify(state.cart)
    },
    UPDATE_ITEM(state, payload) {
      const item = state.items.find(x => x.article == payload.article)
      Object.assign(item, payload)
    },
    UPDATE_ACCESSORY(state, payload) {
      const accessory = state.accessories.find(x => x.article == payload.article)
      Object.assign(accessory, payload)
    }
  },
  actions: {
    async setItems({ commit, state }) {
      if (state.items.length) return
      let response = await axios.get('/images/data.json', { headers: { 'Cache-Control': 'no-cache' } })
      commit('SET_DATA', response.data)
      setTimeout(() => document.dispatchEvent(new Event('x-app-rendered')), 500)
    },
    addToCart({ commit }, item) {
      Vue.prototype.$ga.event('ecommerce', 'add_to_cart', item.article, item.count)
      commit('ADD_TO_CART', item)
    },
    removeFromCart({ commit }, item) {
      commit('REMOVE_FROM_CART', item)
    },
    emptyCart({ commit }) {
      commit('EMPTY_CART')
    },
    loadCart({ commit }, cart) {
      commit('LOAD_CART', cart)
    },
    updateItem({ commit }, item) {
      commit('UPDATE_ITEM', item)
    },
    updateAccessory({ commit }, accessory) {
      commit('UPDATE_ACCESSORY', accessory)
    }
  },
  modules: {
  }
})
